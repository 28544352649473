import React from "react";
import {FunctionComponent, ReactElement} from "react";
import {Route, Routes} from "react-router-dom";
import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {Home} from "../pages/Home";
import {TermsOfUse} from "../pages/TermsOfUse";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { FAQ } from "../pages/FAQ";

export type RoutesList = {
  path: RoutesPathList;
  component: FunctionComponent;
  meta: {
    title: string;
  };
};

export enum RoutesPathList {
  HOME = '/',
  TERMS = '/terms',
  PRIVACY = '/privacy-policy',
  FAQ = '/faq',
}

export const ROUTES: RoutesList[] = [
  {
    path: RoutesPathList.HOME,
    component: Home,
    meta: {
      title: 'Home',
    },
  },
  {
    path: RoutesPathList.TERMS,
    component: TermsOfUse,
    meta: {
      title: 'Terms',
    },
  },
  {
    path: RoutesPathList.PRIVACY,
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy',
    },
  },
  {
    path: RoutesPathList.FAQ,
    component: FAQ
    ,
    meta: {
      title: 'FAQ',
    },
  },
];


export const Router = () => {
  const renderPages = (child: ReactElement): ReactElement => {
    return (
      <div className="main-wrapper">
        <Header/>
        <div className="main-container">
          {child}
        </div>
        <Footer/>
      </div>
    );
  };

  return (
    <Routes>
      {ROUTES.map((item, index) => {
        const PageComponent = item.component;
        return <Route
          key={index}
          path={item.path}
          element={renderPages(<PageComponent key={item.path} />)}
        />
      })}
    </Routes>
  );
};
