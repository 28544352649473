import React, { FC } from "react";
import Collapsible from "./Collapsible";

export const ForUsers: FC = () => {
  return <div>
    <Collapsible title="Sign-up">
      <p>Watch the video below to see how to sign up in the CUB app</p>
      <iframe title="Watch the video below to see how to sign up in the CUB app"
              src="https://drive.google.com/file/d/1kVVrGE-apwTUmKPA0Ma7wzDYQWg9Fs7J/preview" width="640" height="480"
              allow="autoplay"></iframe>
    </Collapsible>
    <Collapsible title="Find activities">
      <p>Watch the video below to see how to find activities</p>
      <iframe title="Watch the video below to see how to find activities"
              src="https://drive.google.com/file/d/1yMZ5hSF8kQnNTsttG1qsYy7dHZmURMG5/preview" width="640" height="480"
              allow="autoplay"></iframe>
    </Collapsible>
    <Collapsible title="Purchase a ticket">
      <p>Watch the video below to see how to purchase a ticket</p>
      <iframe title="Watch the video below to see how to purchase a ticket"
              src="https://drive.google.com/file/d/1FV77w-Qr0Bd4GtBPoslnwklGVcScgjDj/preview" width="640" height="480"
              allow="autoplay"></iframe>
    </Collapsible>
    <Collapsible title="Come to the event and enjoy">
      <p>Watch the video below to see how to participate in the activity with the purchased ticket</p>
      <iframe title="Watch the video below to see how to participate in the activity with the purchased ticket"
              src="https://drive.google.com/file/d/11LtFIgrjPDDL9p91bczuQzLkfzVPz5oU/preview" width="640" height="480"
              allow="autoplay"></iframe>
    </Collapsible>
    <Collapsible title="Leave a feedback">
      <p>Watch the video below to see how to leave a feedback in the CUB app</p>
      <iframe title="Watch the video below to see how to leave a feedback in the CUB app"
              src="https://drive.google.com/file/d/1Rcaog9lJiSe_XMi3EuC5kWfQsP4BZW8F/preview" width="640" height="480"
              allow="autoplay"></iframe>
    </Collapsible>
    <Collapsible title="Problems">
      <p>If you had a problem with the event, and couldn’t participate in the event you can call the event organizer and
        ask to reschedule the date and time. In case of organizer’s fault or having troubles with the event you can
        contact us for the investigation by email contact@cub4.me
      </p>
    </Collapsible>
    <Collapsible title="Refunds">
      If you can’t participate in the event you can call the event organizer and ask them for a refund. If they decline
      your request please describe a case to the support email contact@cub4.me
    </Collapsible>
  </div>;
};
