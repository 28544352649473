import React from "react";
import "./index.scss";

import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/instagram.svg';
import twitter from '../../assets/images/twitter.svg';

export const Footer = () => {

  return (
    <div className="footer-wrapper">
      <div className="main-container">
        <div>
          <ul className="main-menu">
            <li>
              <a href="/terms">Terms of Service</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div>
          <ul className="social-links">
            <li>
              <a href="/">
              <img src={facebook} alt="facebook"/>
              </a>
            </li>
            <li>
              <a href='/'>
                <img src={instagram} alt="instagram"/>
              </a>
            </li>
            <li>
              <a href='/'>
                <img src={twitter} alt="twitter"/>
              </a>
            </li>
          </ul>
        </div>
        <div className="copyright">© Copyright 2022 CUB</div>
      </div>
    </div>
  );
};
