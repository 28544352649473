import React, { useState } from "react";
import arrow from "../../assets/icons/arrow.svg";
import arrowActive from "../../assets/icons/arrow-active.svg";

interface IProps {
  open?: boolean;
  title: string;
  children: any;
}

const Collapsible: React.FC<IProps> = ({ open, children, title }) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div className="card">
        <div>
          <div className="card-title" onClick={handleFilterOpening}>
            <h3 className="font-weight-bold">{title}</h3>
            <button type="button" className="btn">
              {!isOpen ? (
                <img src={arrow} alt="arrow" />

              ) : (
                <img src={arrowActive} alt="arrow" />

              )}
            </button>
          </div>
        </div>
        <div>{isOpen && <div className="card-content">{children}</div>}</div>
      </div>
    </>
  );
};

export default Collapsible;
