import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.scss';
import {App} from "./App";
import {BrowserRouter} from "react-router-dom";

const CONTAINER_SELECTOR = 'root';

const container = document.getElementById(CONTAINER_SELECTOR);

if (!container) {
  throw new Error(`Element with id "${CONTAINER_SELECTOR}" not found`);
}
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>
);
