import React, {FC} from "react";
import mainImg from "../../assets/images/main-img.png";
import mainImg2x from "../../assets/images/main-img-2x.png";
import appStore from "../../assets/images/app-store.svg";
import googlePlay from "../../assets/images/google-play.svg";

export const Home: FC = () => {
  return (
    <div className="main-screen">
      <div className="main-screen-content">
        <h1>Fill Your Life<br/>
          With <span>Emotions</span></h1>
        <p>Thousands of attractions and activities in your city. Carting, Paragliding,
          Yoga, Music festivals, Business courses, Gulf, Bar meetings, Hiking, Gym,
          Tennis, Snowboarding, Reading club, Football, Drum classes, Chess, Vocal
          lessons, Rafting, Jiu Jitsu, Floating, Painting, Excursions and more..</p>
        <ul className="store-block">
          <li>
            <a href="/">
              <img src={appStore} alt="App store"/>
            </a>
          </li>
          <li>
            <a href="/">
              <img src={googlePlay} alt="Google play"/>
            </a>
          </li>
        </ul>
      </div>
      <div>
        <img src={mainImg} srcSet={`${mainImg} 1x, ${mainImg2x} 2x`}
             alt="Thousands of attractions and activities in your city. Carting, Paragliding,
          Yoga, Music festivals, Business courses, Gulf, Bar meetings, Hiking, Gym,
          Tennis, Snowboarding, Reading club, Football, Drum classes, Chess, Vocal
          lessons, Rafting, Jiu Jitsu, Floating, Painting, Excursions and more.."/>
      </div>
    </div>
  )
}
