import React, { FC, useRef } from "react";

import "./index.scss";
import { ForUsers } from "./ForUsers";
import { ForOrganizers } from "./ForOrganizers";
import { Tabs, TabsRef } from "@appello/web-ui";

export const FAQ: FC = () => {
  const tabsRef = useRef<TabsRef>(null);

  return (
    <div className="terms-wrapper">
      <h1 className="text-center">
        FAQ
      </h1>
      <Tabs
        tabsRef={tabsRef}
        headListClassName="mb-8"
        items={[
          {
            title: 'For users',
            element: <ForUsers />,
          },
          {
            title: 'For organizers',
            element: <ForOrganizers />,
          },
        ]}
      />
    </div>
  );
};
