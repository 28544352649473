import React, {FC} from "react";

import "./index.scss";

export const PrivacyPolicy: FC = () => {
  return (
    <div className="terms-wrapper">
      <h1>
        Cub Privacy Policy
      </h1>
      <p>Cub4me, Inc. ("Cub4me," "we," "us," or "our") operates the Cub platform ("Platform").
        This Privacy Policy describes how we collect, use, and disclose information in connection with your use of the App.
      </p>
      <ol>
        <li>
          <h3>Information We Collect</h3>
          <p>We collect several types of information from Users and Organizers ("you") who use the App:</p>
          <ul>
            <li>Account Information: When you create a Cub account, we collect information such as your name, email address, phone number (optional), and login credentials.
            </li>
            <li>
              Activity Information: Organizers provide information about their Activities, including descriptions, pricing, images, videos, location data, and safety precautions.
            </li>
            <li>
              Transaction Information: When you book an Activity, we collect information about the transaction, such as the Activity booked, the date and time of the booking, and the amount paid.
            </li>
            <li>
              Usage Information: We collect information about your use of the App, such as the Activities you browse and book, the reviews you leave, and the searches you perform.
            </li>
            <li>
              Device Information: We collect information about the device you use to access the App, such as the device type, operating system, IP address, and unique device identifier.
            </li>
          </ul>
        </li>
        <li>
          <h3>How We Use Your Information</h3>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>To operate and improve the App.</li>
            <li>To facilitate communication between Users and Organizers.</li>
            <li>To process bookings and payments.</li>
            <li>To send you marketing and promotional communications (with your consent). This may include using content you publish on the App, such as reviews, images, and videos.
            </li>
            <li>To respond to your inquiries and provide customer support.</li>
            <li>To comply with legal and regulatory requirements.</li>
          </ul>
        </li>
        <li>
          <h3>User Content and Marketing
          </h3>
          <p>By submitting content to the App, including but not limited to reviews, images, and videos, you grant Cub4me a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, publish, and translate such content for our marketing and promotional purposes.  This may include using your content on the App, on our website, and in social media posts.
          </p>
          <p>You can choose to opt out of having your content used for marketing purposes by contacting us at [Insert your email address].  However, please note that we may still use your content for other purposes, such as displaying it on the App or responding to your inquiries.
          </p>
        </li>
        <li>
          <h3>Information Sharing</h3>
          <p>We may share your information with third-party service providers who help us operate the App and provide related services.  These service providers are obligated to protect your information and use it only for the purposes we specify.</p>
            <p>We may also share your information with Organizers in connection with your bookings.  This information may include your name, email address, and any other information necessary for the Organizer to provide the Activity.</p>
            <p>We may disclose your information if required to do so by law or in the good faith belief that such disclosure is necessary to protect our rights or the rights of others.</p>
        </li>
        <li>
          <h3>Your Choices</h3>
          <p>You can control how your information is used in several ways:</p>
          <ul>
            <li>You can access and update your account information in the App settings.</li>
            <li>
              You can opt out of receiving marketing communications from us by following the unsubscribe instructions in those communications.
            </li>
            <li>
              You can control your device settings to limit the information collected by the App.
            </li>
            <li>
              You can opt out of having your content used for marketing purposes by contacting us at [Insert your email address].
            </li>
          </ul>
        </li>
        <li>
          <h3>Data Retention</h3>
          <p>We will retain your information for as long as your account is active or as needed to provide you with services.  We will also retain your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
          </p>
        </li>
        <li>
          <h3>Children's Privacy</h3>
          <p>The App is not directed to children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us.  If we learn that we have collected personal information from a child under 13, we will delete that information to the extent possible.
          </p>
        </li>
        <li>
          <h3>Security</h3>
          <p>We take reasonable steps to protect the information you provide to us from unauthorized access, disclosure, alteration, or destruction.  However, no security system is perfect, and we cannot guarantee the security of your information.
          </p>
        </li>
        <li>
          <h3>International Transfers</h3>
          <p>Your information may be transferred to and processed in countries other than your own.  These countries may have different data protection laws than your own country.
          </p>
        </li>
        <li>
          <h3>Changes to This Privacy Policy</h3>
          <p>We may update this Privacy Policy from time to time.  We will notify you of any changes by posting the new Privacy Policy on the App.  You are advised to review this Privacy Policy periodically for any changes.
          </p>
        </li>
        <li>
          <h3>Contact Us</h3>
          <p>If you have any questions about these Organizer Terms, please contact us at business@cub4.me.</p>
            <p>By using Cub platform, you agree to be bound by these Privacy Policy. If you do not agree to this
              Privacy Policy, you may not use the Platform.
            </p>
        </li>
      </ol>
    </div>
  );
};
