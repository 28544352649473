import React from "react";

import "./index.scss";
import logo from '../../assets/images/logo.svg';

export const Header = () => {
  return (
    <div className="header-wrapper">
      <div className="header-container">
        <div>
          <img src={logo} alt="logo"/>
        </div>
        <ul className="main-menu">
          <li>
            <a href='/faq'>FAQ</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
