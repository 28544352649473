import React, { FC } from "react";

import "./index.scss";

export const TermsOfUse: FC = () => {
  return (
    <div className="terms-wrapper">
      <h1>
        Terms and Conditions
      </h1>
      <ol>
        <li>
          <h2>Terms and Conditions for users</h2>
          <p><strong>Welcome to Cub!</strong></p>
          <p>Cub, a mobile application operated by Cub4me, Inc. ("Cub4me," "we," "us," or "our"), is a platform that
            connects users ("Users") with activity organizers ("Organizers") who offer unique experiences and events
            ("Activities"). These Terms and Conditions ("Terms")
            govern your access and use of the Cub app and related services (collectively, the "Platform").
          </p>
          <ol>
            <li>
              <h3>Our Role as a Platform</h3>
              <p>Cub4me acts as an intermediary platform connecting Users with Organizers. We do not organize, own, or
                control any Activities offered on the Platform. We are solely responsible for facilitating communication
                and transactions between Users and Organizers.
              </p>
            </li>
            <li>
              <h3>User Content and Activities
              </h3>
              <p>Organizers are solely responsible for the content
                they publish, including descriptions, pricing, images, and videos, of their Activities.
                Users are responsible for carefully evaluating the suitability and safety of any Activity before
                booking.
              </p>
            </li>
            <li>
              <h3>Disclaimer of Liability
              </h3>
              <p>
                Cub4me DISCLAIMS ALL LIABILITY related to:
              </p>
              <ul>
                <li>The accuracy, completeness, or safety of Activity content.
                </li>
                <li>
                  The quality, safety, or enjoyment of any Activity.
                </li>
                <li>
                  Any disputes between Users and Organizers.
                </li>
                <li>
                  Any injuries, damages, or losses arising from Activities.
                </li>
              </ul>
            </li>
            <li>
              <h3>User Conduct
              </h3>
              <p>Users are prohibited from:
              </p>
              <ul>
                <li>Uploading illegal or offensive content.
                </li>
                <li>Disrupting the Platform or engaging in fraudulent activity.
                </li>
                <li>Impersonating another User or Organizer.
                </li>
                <li>Violating any applicable laws or regulations.
                </li>
              </ul>
            </li>
            <li>
              <h3>User Reviews
              </h3>
              <p>Users may leave reviews of Activities, including uploading images and videos.
                However, Users are solely responsible for the content of their reviews and agree not to post anything
                that is illegal,
                offensive, or untrue.
                Cub4me reserves the right to remove any review at its sole discretion.

              </p>
            </li>
            <li>
              <h3>Limitation of Liability
              </h3>
              <p>Cub4me's liability to you for any cause whatsoever, and regardless of the form of the action, will at
                all times be limited to the amount paid, if any, by you to Cub4me.
              </p>
            </li>
            <li>
              <h3>Governing Law and Dispute Resolution
              </h3>
              <p>These Terms shall be governed by and construed in accordance with the laws of the State of Delaware,
                without regard to its conflict of law provisions.
                Any dispute arising out of or relating to these Terms or your use of the Platform shall be resolved by
                binding arbitration in
                accordance with the rules of the American Arbitration Association (<a href="http://www.adr.org/"
                                                                                      target="_blank"
                                                                                      rel="noreferrer">http://www.adr.org/</a>).
              </p>
            </li>
            <li>
              <h3>Termination</h3>
              <p>Cub4me may terminate your access to the Platform at any time and for any reason, with or without
                notice.</p>
            </li>
            <li>
              <h3>Entire Agreement</h3>
              <p>These Terms constitute the entire agreement between you and Cub4me regarding your use of the Platform
                and supersede all prior or contemporaneous communications and proposals, whether oral or written.</p>
            </li>
            <li>
              <h3>Updates to Terms</h3>
              <p>Cub4me reserves the right to update these Terms at any time.
                Your continued use of the Platform following the posting of any revised Terms constitutes your
                acceptance of the revised Terms.</p>
            </li>
            <li>
              <h3>Contact Us</h3>
              <p>
                If you have any questions about these Terms, please contact us at <a
                href="mailto:contact@cub4.me">contact@cub4.me</a>.
                By downloading and using the Cub app, you agree to be bound by these Terms. If you do not agree to these
                Terms, you may not use the Platform.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>Terms and Conditions for organizers</h2>
          <p><strong>Welcome Organizers!</strong></p>
          <p>These Cub Organizer Terms and Conditions ("Organizer Terms") supplement the Cub Terms and Conditions
            ("Terms") and apply specifically to your use of the Cub platform ("Platform") to offer and manage Activities
            ("Activities").
          </p>
          <ol>
            <li>
              <h3>Your Responsibilities</h3>
              <ul>
                <li>Content and Safety: You are solely responsible for the accuracy, completeness, and safety of all
                  content related to your Activities, including descriptions, pricing, images, videos, and safety
                  precautions.
                </li>
                <li>Compliance: You agree to comply with all applicable laws and regulations related to the Activities
                  you offer.
                </li>
                <li>Insurance: You may be required to obtain appropriate insurance coverage for your Activities.</li>
                <li>Customer Service: You are responsible for providing excellent customer service to Users who book
                  your Activities. This includes timely and accurate communication, addressing User inquiries, and
                  resolving any disputes that may arise.
                </li>
              </ul>
            </li>
            <li>
              <h3>Fees and Payments</h3>
              <ul>
                <li>Cub Fee: Cub4me takes a 10% fee from every User purchase of your Activities.
                </li>
                <li> Earnings & Payouts: You can view your current balance and past payouts in the "Earnings & Payouts"
                  section of the app.
                </li>
                <li> Bank Details: You are responsible for providing accurate company/individual name and valid bank
                  details for receiving payouts.
                </li>
                <li>
                  Payout Schedule: Payouts are processed twice a month. Funds for tickets used by customers will be
                  reflected in your balance and added to the upcoming payout.

                </li>
              </ul>
            </li>
            <li>
              <h3>Refunds and Cancellations</h3>
              <ul>
                <li>You are responsible for establishing your own refund and cancellation policies for your Activities.
                  Cub4me may, in its sole discretion, offer a refund to Users in exceptional circumstances, in which
                  case, Cub4me reserves the right to recoup the refunded amount from your next payout.
                </li>
              </ul>
            </li>
            <li>
              <h3>Cub4me's Rights
              </h3>
              <ul>
                <li>Termination: Cub4me may terminate your access to the Platform at any time and for any reason, with
                  or without notice. This may include but is not limited to violations of these Organizer Terms, the
                  Terms, or any applicable laws.
                </li>
                <li>Content Removal: Cub4me reserves the right to remove any Activity content that it deems to be
                  inaccurate, misleading, unsafe, offensive, or in violation of these Terms or applicable laws.
                </li>
              </ul>
            </li>
            <li>
              <h3>User Reviews and Feedback
              </h3>
              <p>You agree that Users may leave reviews and feedback about your Activities. You may choose to respond to
                reviews, but you are not obligated to do so.
              </p>
            </li>
            <li>
              <h3>Liability</h3>
              <p>Cub4me DISCLAIMS ALL LIABILITY related to:
              </p>
              <ul>
                <li>The quality, safety, or enjoyment of any Activity.
                </li>
                <li>
                  Any disputes between Users and you.

                </li>
              </ul>
            </li>
            <li>
              <h3>Representations and Warranties
              </h3>
              <p>You represent and warrant that you have the full right, power, and authority to offer the Activities on
                the Platform.
              </p>
            </li>
            <li>
              <h3>Taxes</h3>
              <p>Cub4me acts as a facilitator for transactions between users and organizers. We are not responsible for
                collecting or paying taxes on your behalf. As an organizer, you are solely responsible for understanding
                and complying with all applicable tax laws in your jurisdiction.
                We recommend that you consult with a tax professional to determine your tax obligations related to the
                income you earn through Cub4me.
              </p>
            </li>
            <li>
              <h3>Governing Law and Dispute Resolution
              </h3>
              <p>These Organizer Terms shall be governed by and construed in accordance with the laws of the State of
                [Insert your state], without regard to its conflict of law provisions. Any dispute arising out of or
                relating to these Terms or your use of the Platform shall be resolved by binding arbitration in
                accordance with the rules of the American Arbitration Association (<a href="http://www.adr.org/"
                                                                                      target="_blank"
                                                                                      rel="noreferrer">http://www.adr.org/</a>).
              </p>
            </li>
            <li>
              <h3>Entire Agreement
              </h3>
              <p>These Organizer Terms constitute the entire agreement between you and Cub4me regarding your use of the
                Platform and supersede all prior or contemporaneous communications and proposals, whether oral or
                written.
              </p>
            </li>
            <li>
              <h3>Updates to Terms</h3>
              <p>Cub4me reserves the right to update these Organizer Terms at any time. Your continued use of the
                Platform following the posting of any revised Organizer Terms constitutes your acceptance of the revised
                Organizer Terms.
              </p>
            </li>
            <li>
              <h3>Contact Us
              </h3>
              <p>If you have any questions about these Organizer Terms, please contact us at <a
                href="mailto:business@cub4.me">business@cub4.me</a>.
              </p>
              <p>By listing Activities on the Cub platform, you agree to be bound by these Organizer Terms. If you do
                not agree to these Organizer Terms, you may not use the Platform to offer Activities.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
