import React, { FC } from "react";
import Collapsible from "./Collapsible";
import faq from "../../assets/images/faq.jpg";

export const ForOrganizers: FC = () => {
  return <div>
    <Collapsible title="Sign-up as a business">
      <p>Watch the video below to see how to sign-up as a business in the CUB app</p>
      <iframe title="Watch the video below to see how to sign-up as a business in the CUB app"
              src="https://drive.google.com/file/d/11MxVEKIcv0KJv3eROg9ggpKhBBMZ1VV_/preview" width="640"
              height="400"></iframe>
    </Collapsible>
    <Collapsible title="Create an event">
      <p>Watch the video below to see how to create an event</p>
      <iframe title="Watch the video below to see how to create an event"
              src="https://drive.google.com/file/d/1djvVaDn4XYkW9YF6STZo16ugteVWX8Zi/preview" width="640"
              height="400"></iframe>
    </Collapsible>
    <Collapsible title="Receive guests">
      <p>Watch the video below to see how to accept guests to your event/activity.</p>
      <iframe title="Watch the video below to see how to accept guests to your event/activity."
              src="https://drive.google.com/file/d/1afyez8GpkIUfNjM9HNz1S4Rn0xr-RC4t/preview" width="640"
              height="400"></iframe>
    </Collapsible>
    <Collapsible title="Payouts and commissions">
      <p>Commission of the CUB app platform is 10%. When you set the ticket price you see how much you will earn from
        each ticket sold. It’s mandatory to set the ticket price not more than on other resources and your direct sales
        as described in our Terms on Service. CUB Platform takes commission to cover its operational costs, marketing
        and other mandatory expenses. We think it’s fair as organizers also spend even more than 10% of their income for
        marketing and sales purposes to promote their activities.
      </p>
    </Collapsible>
    <Collapsible title="When organizers receive payouts and how to track them?">
      <div><img alt="" src={faq} width="200px" style={{ margin: "auto", display: "block" }} /></div>
      <h4>Selling Tickets.</h4>
      <p>When someone books a ticket for your activity, the money is added to your balance. This means you've earned it,
        but it hasn't been paid out yet.</p>
      <h4>Confirming Participation:</h4>
      <p>Cub ensures everything goes smoothly. We wait for the customer to actually attend and participate in your
        activity before releasing the funds.
      </p>
      <h4>Automatic Payouts go twice a month. Cub processes payouts automatically:</h4>
      <ul>
        <li>
          Mid-month: Between the 15th to 20th day of each month, you'll receive payment for activities where customers
          participated between 1st to 15th day of this month.
        </li>
        <li>
          Month-end: Between the 1st and 5th day of each month, you'll receive payment for activities where customers
          participated in the second half of the previous month between 16th to end-of-the-month.
        </li>
      </ul>
      <h4>Example</h4>
      <ul>
        <li>On March 1st, someone books a ticket for your activity happening on March 20th. The money goes into your
          balance.
        </li>
        <li>
          Once they participate on March 20th, the payment is queued for the next mid-month payout (processing between
          April 1st and 5th).
        </li>
      </ul>
      <h4>Key Points:</h4>
      <ul>
        <li>You only get paid after a customer attends your activity.</li>
        <li>Payouts are automatic, so you don't need to do anything extra.</li>
        <li>You can see your current balance and past payouts in the "Earnings & Payouts" section of the app settings.
        </li>
      </ul>
    </Collapsible>
    <Collapsible title="Refunds to users">
      <p>If the user couldn’t come to the event you can offer them another date/time. If you can’t find a solution and
        can’t accept the user to the event you can select a ticket and make a refund.
      </p>
    </Collapsible>
    <Collapsible title="Accepting users">
      <p>It’s mandatory to scan the QR code of the ticket from every guest to confirm they come and to receive money for
        the ticket sold. If a user purchased multiple tickets, they have only one QR code which shows you the amount of
        tickets purchased by this user to let people come with this person to participate in your event/activity.
      </p>
    </Collapsible>
    <Collapsible title="Organizing different roles for the business.">
      <p>For now there is no feature to set roles for the business and allocate other users to manage your events.
        Currently only business owner/seller can sell tickets and accept guests by scanning QR codes of tickets. The
        feature with different business roles will be added to the app soon.
      </p>
    </Collapsible>
    <Collapsible title="Problems">
      In case of questions please reach us by email: business@cub4.me
    </Collapsible>
  </div>;
};
